import { Toaster } from '@careos/react-ui/Toast';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import React, { Suspense } from 'react';

import { TheNavBar } from '@/components/the-nav-bar';

import { NotFoundComponent } from './_not-found.lazy';

const TanStackRouterDevtools =
  process.env.NODE_ENV !== 'development'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

export const Route = createRootRoute({
  component: () => (
    <>
      <div className="relative flex min-h-screen flex-col bg-[url('/background.svg')] bg-cover bg-no-repeat">
        <TheNavBar />
        <main>
          <Outlet />
          <Toaster />
        </main>
      </div>
      <Suspense>
        <TanStackRouterDevtools />
        <ReactQueryDevtools />
      </Suspense>
    </>
  ),
  notFoundComponent: NotFoundComponent,
});
